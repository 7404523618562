<template>
  <div>
    <div class="main-title">新生列表</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0=""></ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l">
          <el-select v-model="searchType" clearable slot="prepend" placeholder="选择类型" size="mini">
            <el-option label="UID" :value="1"></el-option>
            <el-option label="班主任UID" :value="2"></el-option>
            <el-option label="销售顾问UID" :value="3"></el-option>
          </el-select>
          <el-input class="margin-l-sm" v-model="uid" placeholder="输入uid" size="mini" style="width: 160px" clearable>
          </el-input>
          <el-input class="margin-l-sm" v-model="searchData.name" placeholder="姓名" size="mini" style="width: 160px" clearable />
          <el-select class="margin-l-sm" v-model="searchData.status" clearable slot="prepend" placeholder="状态" size="mini">
            <el-option label="试听跟进中" :value="1"></el-option>
            <el-option label="已报名" :value="2"></el-option>
          </el-select>
        </span>


        <span class="margin-l-sm fl-l">
          <el-date-picker size="small" v-model="value2" type="daterange" align="right" unlink-panels range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </span>



        <span class="margin-l-sm fl-l">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>

        </span>

        <span class="fl-r"><el-button type="success" icon="el-icon-plus" size="mini" @click="$router.push('/newStudent/add')">添加</el-button> </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content" v-loading="loading">
        <el-table :data="tableData" style="width: 100%" size="mini">
          <el-table-column prop="id" label="id"> </el-table-column>
          <el-table-column prop="uid" label="uid"> </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="grade" label="年级"> </el-table-column>
          <el-table-column prop="apply_product" label="报名服务"> </el-table-column>
          <el-table-column prop="teacher_name" label="班主任"> </el-table-column>
          <el-table-column prop="counselor_name" label="销售顾问"> </el-table-column>
          <el-table-column label="续费跟进情况">
            <template slot-scope="scope">
              {{scope.row.remark}}
            </template>
          </el-table-column>
          <el-table-column prop="type" label="学习类型">
            <template slot-scope="scope">
              {{typeText(scope.row.type)}}
            </template>
          </el-table-column>

          <el-table-column prop="apply_at" label="报名时间">
            <template slot-scope="scope">
              {{scope.row.apply_at.substr(0,10)}}
            </template>
          </el-table-column>

          <el-table-column prop="created_at" label="创建时间">
          </el-table-column>

          <el-table-column label="状态" property="address" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" class="state-up"
              ><i></i>试听跟进中</span
              >
              <span v-if="scope.row.status == 2" class="state-red"
              ><i></i>已报名</span
              >
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
               <span v-if="!scope.row.deleted_at">
                  <el-link type="primary" @click="$router.push('/newStudent/edit/'+scope.row.id)">编辑</el-link>
                 -
                  <el-link type="primary" @click="$router.push('/studentNameCard?uid='+scope.row.uid)">查看档案</el-link>
                 -
                  <el-popconfirm
                      icon="el-icon-info"
                      icon-color="red"
                      title="会员关闭不可恢复,确定关闭吗？"
                      @confirm="del(scope.row.id)"
                  >
                  <el-link slot="reference" type="primary" size="mini">删除</el-link>
                  </el-popconfirm>

               </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                         layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize" @current-change="setPage"
                         :total="total">
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import router from "@/routers/admin";
export default {
  name: "list",
  data() {
    return {
      typeOption:[
        {value:1,label:'录播课',},
        {value:2,label:'直播课',},
        {value:3,label:'直播小班课',},
        {value:4,label:'直播大班课',},
      ],
      searchData: {
        uid:'',
        teacher_uid:'',
        counselor_uid:'',
        name: "",
        status: "",
      },
      loading: false,
      tableData: [],
      searchType: null,
      uid: null,
      value2: [],
      page: 1,
      pageSize: 10,
      total: 0,
    };
  },
  methods: {
    router() {
      return router
    },
    ...mapActions("user", ["getNewStudentList"]),
    ...mapActions("common", ["delete"]),
    search() {
      this.searchData.datePick = this.value2;


      switch (this.searchType){
        case 1:
          this.searchData.uid = this.uid
          this.searchData.teacher_uid = ''
          this.searchData.counselor_uid = ''
          break;
        case 2:
          this.searchData.uid = ''
          this.searchData.teacher_uid = this.uid
          this.searchData.counselor_uid = ''
          break;
        case 3:
          this.searchData.uid = ''
          this.searchData.teacher_uid = ''
          this.searchData.counselor_uid = this.uid
          break;
      }

      this.getList();
    },
    async getList() {
      this.loading = true;
      let form = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      }
      const { data } = await this.getNewStudentList(form);
      this.total = data.total;
      this.tableData = data.list;
      this.loading = false;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    typeText(type){
      let text = '';
      console.log(type)
      switch (type){
        case 1: text='录播课'; break;
        case 2: text='直播课'; break;
        case 3: text='直播小班课'; break;
        case 4: text='直播大班课'; break;
      }
      return text;
    },
    del(id){
      this.delete({ id: id, model: "StudentProfile\\NewStudent" }).then((res) => {
        if (res.ret === 0) {
          this.$message.success("删除成功");
          this.getList();
        }
      });
    }
  },
  mounted() {
    this.getList();
  },
};
</script>

<style>
.el-popover {
  min-width: 80px;
}
</style>
